import { observer } from 'mobx-react';
import * as React from 'react';
import { CategoryHierarchy } from '@msdyn365-commerce/commerce-entities';
import { CommerceProperty, MediaLocation, TextValueTranslation } from '@msdyn365-commerce/retail-proxy';

export interface ICategoryProps {
    RecordId: number;
    OfflineImage?: string;
    Name?: string;
    ParentCategory?: number;
    DisplayOrder?: number;
    Images?: MediaLocation[];
    NameTranslations?: TextValueTranslation[];
    ExtensionProperties?: CommerceProperty[];
    Children?: ICategoryProps[] | undefined;
    Slug?: string;
    Url?: string;
    NeutralizedName?: string;
    expanded?: boolean;
}
export interface ICategoryListingState {
    displayChildren: boolean;
}

export interface ICategoryListingProps {
    categoryHierarchy: CategoryHierarchy;
    hideCategory?: number;
}

@observer
export default class CategoryListing extends React.PureComponent<ICategoryListingProps, ICategoryListingState> {
    constructor(props: Readonly<ICategoryListingProps>) {
        super(props);
        this.state = {
            displayChildren: true
        };
    }

    private readonly _toggleCategory = () => {
        this.setState({ displayChildren: !this.state.displayChildren });
    };

    public render(): JSX.Element | null {
        const currentCategory = this.props.categoryHierarchy;
        const { displayChildren } = this.state;

        if (this.props.hideCategory === currentCategory.RecordId || (currentCategory.Children ?? []).length === 0) {
            return null;
        }
        return (
            <div className='category-listing-wrapper'>
                <div className='category-list'>
                    {currentCategory.Children && (
                        <button
                            className={displayChildren ? 'ms-category-list__toggle_expanded' : 'ms-category-list__toggle_collapse'}
                            type='button'
                            onClick={this._toggleCategory}
                        >
                            <a href={currentCategory.Url}> {currentCategory?.Name} </a>
                        </button>
                    )}
                    {displayChildren
                        ? currentCategory.Children?.map((item: ICategoryProps, index: number) => {
                              return (
                                  <div className='category-list-items' key={index}>
                                      <a href={item.Url}> {item?.Name} </a>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
        );
    }
}
